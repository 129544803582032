export class BaseRequest { }

export class SignInRequest extends BaseRequest {
  email: string
  password: string

  constructor(email: string, password: string) {
    super()
    this.email = email
    this.password = password
  }
}
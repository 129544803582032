import UserApiRepository from "@/usecase/repository/api/userApiRepository"
import {Method, validateResponse} from "@/data/api/BaseApi"
import {SessionResponse} from "@/data/api/response"
import {Session} from "@/domain/entity"
import {SignInRequest} from "@/data/api/request";
import {sessionTo} from "@/data/api/response/mapper";
import {injectable} from "inversify";

@injectable()
export default class UserApi implements UserApiRepository {

  public signIn(email: string, password: string): Promise<Session> {
    return new Promise<Session>((resolve, reject) => {
      validateResponse<SessionResponse>(Method.POST, '/v1.0.0/user/signin', new SignInRequest(email, password))
        .then(response => {
          if (response.data != null) {
            resolve(sessionTo(response.data))
          } else {
            reject(response.error)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}

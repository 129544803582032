































































import {BaseNav} from '@/app/components/base/index.js';
import {Component, Prop, Vue} from "vue-property-decorator";
import {SessionStore} from "@/app/store/session";
import {getModule} from "vuex-module-decorators";

@Component({
  components: {BaseNav}
})
export default class DashboardNavbar extends Vue {
  @Prop({required: false, default: 'default', type: String}) type: string
  @Prop({required: false, default: '', type: String}) nameUser: string
  @Prop({required: false, default: '', type: String}) urlPhoto: string

  activeNotifications = false
  showMenu = false
  searchModalVisible = false
  searchQuery = ''

  get sessionStore(): SessionStore {
    return getModule(SessionStore, this.$store)
  }

  routeName() {
    const {name}: string = this.$route;
    return this.capitalizeFirstLetter(name);
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async logout() {
    await this.sessionStore.callSignOut()
  }

  toggleNotificationDropDown() {
    this.activeNotifications = !this.activeNotifications;
  }

  closeDropDown() {
    this.activeNotifications = false;
  }

  created() {
    /*this.sessionStore.callGetSession()
      .then(session => {
        console.log('created [session]: ', session)
        if (session.dataSession) {
          this.nameUser = session.dataSession?.name_user
          console.log('created [nameUser]: ', session.dataSession?.name_user)
        }
      })*/
  }

}

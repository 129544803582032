import { Feature } from "@/domain/entity";
import {inject, injectable} from "inversify";
import FeatureApiRepository from "@/usecase/repository/api/featureApiRepository";

export interface SaveFeature {
  execute(model: Feature): Promise<boolean>;
}

@injectable()
export class UseCaseSaveFeature implements SaveFeature {
    constructor(
        @inject("FeatureApiRepository") private featureApiRepository: FeatureApiRepository) {
    }

    execute(model: Feature): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.featureApiRepository.save(model)
                .then(succes => {
                resolve(succes)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}

import {BaseApiError} from "@/domain/entity/BaseApiError";

export class ApiException extends BaseApiError {

  constructor(code?: number, message_user?: string, message_dev?: string) {
    super(code, message_user, message_dev);
    this.code = code
    this.message_user = message_user
    this.message_dev = message_dev
  }

}

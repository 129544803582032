import {inject, injectable} from "inversify";
import FeatureApiRepository from "@/usecase/repository/api/featureApiRepository";

export interface DeleteFeatureById {
  execute(id_feature: string): Promise<boolean>;
}

@injectable()
export class UseCaseDeleteFeatureById implements DeleteFeatureById {
    constructor(
        @inject("FeatureApiRepository") private featureApiRepository: FeatureApiRepository) {
    }

    execute(id_feature: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.featureApiRepository.deleteById(id_feature)
                .then(succes => {
                resolve(succes)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}

import { Feature, Pagination } from "@/domain/entity";
import FeatureApiRepository from "@/usecase/repository/api/featureApiRepository";
import { inject, injectable } from "inversify";

export interface GetFeature {
    execute(text: string, page: number): Promise<Pagination<Feature>>;
}

@injectable()
export class UseCaseGetFeature implements GetFeature {

    constructor(
        @inject("FeatureApiRepository") private featureApiRepository: FeatureApiRepository) {
    }

    execute(text: string, page: number): Promise<Pagination<Feature>> {
        return new Promise<Pagination<Feature>>((resolve, reject) => {
            this.featureApiRepository.fetchAll(text, page)
                .then(pagination => {
                resolve(pagination)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}
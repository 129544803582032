import Vue from 'vue'
import Vuex from 'vuex'
import {SessionState, SessionStore} from "@/app/store/session"
import {FirebaseStorageState, FirebaseStorageStore} from "@/app/store/firebaseStorage";
import { NewsState, NewsStore } from '@/app/store/news'
import { CampusState, CampusStore } from './campus';
import { FeatureState, FeatureStore } from './feature';

Vue.use(Vuex)

export interface RootState {
  session: SessionState;
  firebaseStorage: FirebaseStorageState,
  news: NewsState,
  campus: CampusState,
  feature: FeatureState
}

export default new Vuex.Store<RootState>({
  modules: {
    session: SessionStore,
    firebaseStorage: FirebaseStorageStore,
    news: NewsStore,
    campus: CampusStore,
    feature: FeatureStore
  }
})

import { Feature, Pagination } from "@/domain/entity";
import FeatureApiRepository from "@/usecase/repository/api/featureApiRepository";
import { injectable } from "inversify";
import { Method, validateResponse } from "./BaseApi";
import { FeatureResponse, PaginationResponse } from "./response";
import { featureTo, paginationFeatureTo } from "./response/mapper";

@injectable()
export default class FeatureApi implements FeatureApiRepository {

    deleteById(id_feature: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
          validateResponse<boolean>(Method.DELETE, `/v1.0.0/feature/${id_feature}`)
          .then(response => {
            if (!response.error) {
              resolve(true)
            } else {
              reject(response.error)
            }
          })
          .catch(exception => {
            reject(exception)
          })
        })
      }
  
      save(request: Feature): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
          validateResponse<boolean>(Method.POST, '/v1.0.0/feature', request)
          .then(response => {
            if (!response.error) {
              resolve(true)
            } else {
              reject(response.error)
            }
          })
          .catch(exception => {
            reject(exception)
          })
        })
      }
  
      fetchById(id_feature: string): Promise<Feature> {
        return new Promise<Feature>((resolve, reject) => {
          validateResponse<FeatureResponse>(Method.GET, `/v1.0.0/feature/${id_feature}`)
              .then(response => {
                if (response.data != null) {
                  resolve(featureTo(response.data))
                } else {
                  reject(response.error)
                }
              })
              .catch(exception => {
                reject(exception)
              })
        })
      }
  
      public fetchAll(text: string, page: number): Promise<Pagination<Feature>> {
          return new Promise<Pagination<Feature>>((resolve, reject) => {
            validateResponse<PaginationResponse<FeatureResponse>>(Method.GET, `/v1.0.0/feature?text=${text}&page=${page}`)
              .then(response => {
                if (response.data != null) {
                  resolve(paginationFeatureTo(response.data))
                } else {
                  reject(response.error)
                }
              })
              .catch(exception => {
                reject(exception)
              })
          })
      }

}
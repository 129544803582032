import {injectable} from "inversify";
import StorageFirebaseRepository from "@/usecase/repository/firebase/storageFirebaseRepository";
import Firebase from "firebase"
import {getStorageParams} from "@/data/firebase/BaseStorage";

@injectable()
export default class StorageFirebase implements StorageFirebaseRepository {

  constructor() {
    Firebase.initializeApp(getStorageParams())
  }

  public upload(file: File, path: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let progress = 0
      let fileName = Math.random().toString(36).slice(2)
      const storageRef = Firebase.storage().ref(`${path}/${fileName}.jpg`).put(file)
      storageRef
        .on(`state_changed`, snapshot => {
            progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          }, exception => {
            reject(exception)
          },
          () => {
            progress = 100;
            storageRef.snapshot.ref.getDownloadURL()
              .then((url) => {
                resolve(url)
              })
              .catch(exception => {
                reject(exception)
              })
          })
    })
  }
}

import { Campus, Pagination } from "@/domain/entity";
import CampusApiRepository from "@/usecase/repository/api/campusApiRepository";
import { injectable } from "inversify";
import { Method, validateResponse } from "./BaseApi";
import { CampusResponse, PaginationResponse } from "./response";
import { campusTo, paginationCampusTo } from "./response/mapper";

@injectable()
export default class CampusApi implements CampusApiRepository {

    deleteById(id_campus: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
          validateResponse<boolean>(Method.DELETE, `/v1.0.0/campus/${id_campus}`)
          .then(response => {
            if (!response.error) {
              resolve(true)
            } else {
              reject(response.error)
            }
          })
          .catch(exception => {
            reject(exception)
          })
        })
      }
  
      save(request: Campus): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
          validateResponse<boolean>(Method.POST, '/v1.0.0/campus', request)
          .then(response => {
            if (!response.error) {
              resolve(true)
            } else {
              reject(response.error)
            }
          })
          .catch(exception => {
            reject(exception)
          })
        })
      }
  
      fetchById(id_campus: string): Promise<Campus> {
        return new Promise<Campus>((resolve, reject) => {
          validateResponse<CampusResponse>(Method.GET, `/v1.0.0/campus/${id_campus}`)
              .then(response => {
                if (response.data != null) {
                  resolve(campusTo(response.data))
                } else {
                  reject(response.error)
                }
              })
              .catch(exception => {
                reject(exception)
              })
        })
      }
  
      public fetchAll(text: string, page: number): Promise<Pagination<Campus>> {
          return new Promise<Pagination<Campus>>((resolve, reject) => {
            validateResponse<PaginationResponse<CampusResponse>>(Method.GET, `/v1.0.0/campus?text=${text}&page=${page}`)
              .then(response => {
                if (response.data != null) {
                  resolve(paginationCampusTo(response.data))
                } else {
                  reject(response.error)
                }
              })
              .catch(exception => {
                reject(exception)
              })
          })
      }

}
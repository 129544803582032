import {BaseApiError} from "@/domain/entity/BaseApiError";

export class BaseResponse<T> {
  public error?: BaseApiError
  public data?: T

  constructor(error?: BaseApiError, data?: T) {
    this.error = error;
    this.data = data;
  }
}

import UserLocalStorageRepository from "@/usecase/repository/localStorage/userLocalStorageRepository";
import {User} from "@/domain/entity";
import {injectable} from "inversify";

@injectable()
export default class UserLocalStorage implements UserLocalStorageRepository {

  getToken(): Promise<string | null> {
    return new Promise<string | null>((resolve) => {
      const token = window.localStorage.getItem('wiener-carriontoken')
      resolve(token)
    })
  }

  getUser(): Promise<User | null> {
    return new Promise<User | null>((resolve) => {
      const jsonUser = window.localStorage.getItem('wiener-carrionuser')
      resolve((jsonUser !== null && jsonUser !== '') ? (JSON.parse(jsonUser) as User) : null)
    })
  }

  setToken(token: string | null): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      window.localStorage.setItem('wiener-carriontoken', token !== null ? token : '')
      resolve(true)
    })
  }

  setUser(user: User): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      window.localStorage.setItem('wiener-carrionuser', user !== null ? (JSON.stringify(user)) : '')
      resolve(true)
    })
  }

}

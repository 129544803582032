<template>
  <div class="main-content bg-primary">

    <div class="main-content">
      <zoom-center-transition
        :duration="pageTransitionDuration"
        mode="out-in">
        <router-view></router-view>
      </zoom-center-transition>
    </div>

    <footer class="py-5" id="footer-main">
      <b-container >
        <b-row align-v="center" class="justify-content-xl-between">
          <b-col xl="6">
            <div class="copyright text-center text-xl-left text-muted">
              
            </div>
          </b-col>
          <b-col xl="6" class="col-xl-6">
            <b-nav class="nav-footer justify-content-center justify-content-xl-end">
              <b-nav-item href="http://quebuu.com" target="_blank">
                
              </b-nav-item>
              <b-nav-item href="http://quebuu.com" target="_blank">
                
              </b-nav-item>
              <b-nav-item href="http://quebuu.com" target="_blank">
                
              </b-nav-item>
              <b-nav-item href="http://quebuu.com" target="_blank">
                
              </b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>
<script>

  import { ZoomCenterTransition } from 'vue2-transitions';

  export default {
    components: {
      ZoomCenterTransition
    },
    props: {
      backgroundColor: {
        type: String,
        default: 'black'
      }
    },
    data() {
      return {
        pageTransitionDuration: 200,
        year: new Date().getFullYear(),
        pageClass: 'login-page'
      };
    },
    computed: {
      title() {
        return `${this.$route.name} Page`;
      }
    },
    methods: {
      setBackgroundColor() {
        document.body.classList.add('bg-primary');
      },
      removeBackgroundColor() {
        document.body.classList.remove('bg-primary');
      },
      updateBackground() {
        if (!this.$route.meta.noBodyBackground) {
          this.setBackgroundColor();
        } else {
          this.removeBackgroundColor()
        }
      }
    },
    beforeDestroy() {
      this.removeBackgroundColor();
    },
    watch: {
      $route: {
        immediate: true,
        handler: function () {
          this.updateBackground()
        }
      }
    }
  };
</script>
<style lang="scss">

</style>

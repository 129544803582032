import {inject, injectable} from "inversify";
import NewsApiRepository from "@/usecase/repository/api/newsApiRepository";

export interface DeleteNewsById {
  execute(id_news: string): Promise<boolean>;
}

@injectable()
export class UseCaseDeleteNewsById implements DeleteNewsById {
    constructor(
        @inject("NewsApiRepository") private newsApiRepository: NewsApiRepository) {
    }

    execute(id_news: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.newsApiRepository.deleteById(id_news)
                .then(succes => {
                resolve(succes)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}

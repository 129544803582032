import { News, Pagination } from "@/domain/entity";
import NewsApiRepository from "@/usecase/repository/api/newsApiRepository";
import { inject, injectable } from "inversify";

export interface GetNews {
    execute(text: string, page: number): Promise<Pagination<News>>;
}

@injectable()
export class UseCaseGetNews implements GetNews {

    constructor(
        @inject("NewsApiRepository") private newsApiRepository: NewsApiRepository) {
    }

    execute(text: string, page: number): Promise<Pagination<News>> {
        return new Promise<Pagination<News>>((resolve, reject) => {
            this.newsApiRepository.fetchAll(text, page)
                .then(pagination => {
                resolve(pagination)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}
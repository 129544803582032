import { News } from "@/domain/entity";
import {inject, injectable} from "inversify";
import NewsApiRepository from "@/usecase/repository/api/newsApiRepository";

export interface SaveNews {
  execute(news: News): Promise<boolean>;
}

@injectable()
export class UseCaseSaveNews implements SaveNews {
    constructor(
        @inject("NewsApiRepository") private newsApiRepository: NewsApiRepository) {
    }

    execute(news: News): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.newsApiRepository.save(news)
                .then(succes => {
                resolve(succes)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}

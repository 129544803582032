import { Campus } from "@/domain/entity";
import {inject, injectable} from "inversify";
import CampusApiRepository from "@/usecase/repository/api/campusApiRepository";

export interface SaveCampus {
  execute(campus: Campus): Promise<boolean>;
}

@injectable()
export class UseCaseSaveCampus implements SaveCampus {
    constructor(
        @inject("CampusApiRepository") private campusApiRepository: CampusApiRepository) {
    }

    execute(news: Campus): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.campusApiRepository.save(news)
                .then(succes => {
                resolve(succes)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}

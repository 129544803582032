import {CampusResponse, FeatureResponse, NewsResponse, PaginationResponse, SessionResponse, UserResponse} from "@/data/api/response/index";
import {Campus, Feature, News, Pagination, Session, User} from "@/domain/entity";

export function userTo(response?: UserResponse): User | null {
  return response != null ? new User(
    response.id_user,
    response.role,
    response.name,
    response.lastname,
    response.email,
    response.password,
    response.url_photo,
    response.password_autogenerate,
    response.active,
    response.date_create,
    response.date_update
  ) : null
}

export function sessionTo(response: SessionResponse): Session {
  return new Session(
    response.token,
    response.id_user,
    response.role,
    response.name,
    response.lastname,
    response.email,
    response.password,
    response.url_photo,
    response.password_autogenerate,
    response.active,
    response.date_create,
    response.date_update
  )
}

export function newsTo(response: NewsResponse): News {
    return new News(
      response.id_news,
      response.id_news,
      response.title,
      response.description,
      response.date,
      response.hours,
      response.type,
      response.action,
      response.institution,
      response.url_photos,
      response.active,
      response.date_create,
      response.date_update
    )
}

export function newsSTo(responses: NewsResponse[]): News[] {
  const list: News[] = []
  for (let item of responses) {
    list.push(newsTo(item))
  }

  return list
}

export function campusSTo(responses: CampusResponse[]): Campus[] {
  const list: Campus[] = []
  for (let item of responses) {
    list.push(campusTo(item))
  }

  return list
}

export function paginationNewsTo(response: PaginationResponse<NewsResponse>): Pagination<News> {
  return new Pagination<News>(
    response.page,
    response.per_page,
    response.next_page,
    response.total,
    newsSTo(response.list)
  )
}

export function campusTo(response: CampusResponse): Campus {
    return new Campus(
      response.id_campus,
      response.id_user,
      response.name,
      response.address,
      response.latitude,
      response.longitude,
      response.institution,
      response.practice,
      response.active,
      response.date_create,
      response.date_update
    )
}

export function paginationCampusTo(response: PaginationResponse<CampusResponse>): Pagination<Campus> {
  return new Pagination<Campus>(
    response.page,
    response.per_page,
    response.next_page,
    response.total,
    campusSTo(response.list)
  )
}

export function featureTo(response: FeatureResponse): Feature {
  return new Feature(
    response.id_feature,
    response.id_user,
    response.name,
    response.description,
    response.institution,
    response.active,
    response.date_create,
    response.date_update
  )
}

export function featureSTo(responses: FeatureResponse[]): Feature[] {
  const list: Feature[] = []
  for (let item of responses) {
    list.push(featureTo(item))
  }

  return list
}

export function paginationFeatureTo(response: PaginationResponse<FeatureResponse>): Pagination<Feature> {
  return new Pagination<Feature>(
    response.page,
    response.per_page,
    response.next_page,
    response.total,
    featureSTo(response.list)
  )
}
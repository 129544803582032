import {inject, injectable} from "inversify";
import CampusApiRepository from "@/usecase/repository/api/campusApiRepository";

export interface DeleteCampusById {
  execute(id_campus: string): Promise<boolean>;
}

@injectable()
export class UseCaseDeleteCampusById implements DeleteCampusById {
    constructor(
        @inject("CampusApiRepository") private campusApiRepository: CampusApiRepository) {
    }

    execute(id_campus: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.campusApiRepository.deleteById(id_campus)
                .then(succes => {
                resolve(succes)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}

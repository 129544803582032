import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css';
import 'vue-datetime/dist/vue-datetime.css'

// @ts-ignore
import { Datetime } from 'vue-datetime'

// @ts-ignore
import DashboardPlugin from './plugins/dashboard-plugin'
import router from './router'
import store from './store'

Vue.use(DashboardPlugin);
Vue.use(VueToast);
// @ts-ignore
Vue.use(Datetime)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

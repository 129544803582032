// 10/10/2023
export interface Storage {
  type: string
  project_id: string
  private_key_id: string
  private_key: string
  client_email: string
  client_id: string
  auth_uri: string
  token_uri: string
  auth_provider_x509_cert_url: string
  client_x509_cert_url: string
  universe_domain: string
  storageBucket: string
}

export function getStorageParams(): Storage {
  return {
    type: "service_account",
    project_id: "wiener-carrion-app-movil",
    private_key_id: "57851402781ff798958a2cb0fb5d9553f40b8e94",
    private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDd8v0vuGADGq0S\nOa5dVTyOO0rOU9OQAtNOet2RW6G2je1Lbp69sF7j/h84yY4R/QUyf6jDba19wxOO\nvjLR9PzqjQPNWhzngWL088RegqbVt0O/uh+E9K4B3UrtFV2KJ8AtITOksDTeQQVX\nPEmKIFMvXnzwmJlblIONeP6u4cnMTxz9GcLg5o03+C9MI8Sixvh8C0ZJ5cL2pMFs\nKROxG8fJ5CqKtYetWit4cV/mliZYjXFoo8nAPvm5hj61ESJdxzIQtLtcKbNOVlkP\nRfcEzIs0p78RzOI5mdzFQnwEhPNQPp66U63K01GFRZAuvqlIKBz638Mm5MzCPMbM\nrVgCl7mjAgMBAAECggEAAfdshYcNWA8HyDOOofERZPPi3nUhA2j4VP0DCDisSXZ0\ndcijIMHL9W/Y+E3WM76ZfzBhCb+SW/I7Msvongec/g8Fy5n9djz6ks+zcQrt+NLI\n3OKvSM702RC24JGMj0l5ixd84lFVW+rDkK74un/oJ3FKNhousasH3LGyaqZBfXfp\nv9Ugf0WTi6wUixWKvjMJkZcZ1fWx3gZYOwK0GzI/p8w0YZWjAVtROfUSM20nWsr9\nIahXnaceIHh35N6bC93Xe+0YqgporbiN4zseWG51r7uAxRAAA42LZY3L3HyRqv6I\nn+X8YcIvouyBecPQXIo6x+VQDil90ihW4s7vn3GUwQKBgQD6fSlzR8JbVD8KUXd9\nEGRphB7mNWRe7krKMMa5Nt2x46FjeOGYNhduamNjbrghMaZ+cdz8riJ2nzqwOhux\nY8dQ2GNHXrF0QXWPgIVhtlUf7gSaeRfazhdR8KbCNL/TQ5CYqZKwkORyRVKPp07k\nQNKCNJFYHDc2g06mi7yDh+jAcQKBgQDi1RTnq6ApHAaknOBg9mm29UbcO4JZSAmd\nSMlRtEf/P2D41ZFAJf94AsE0Yv1rKfvZsjuDAZ9mxkRXhCTMeEkOldI4tLGJWoQV\nMEGBOYmW/AvZZhu391BztvwYCJmOwQ4CDdbow9KhCc5hZtTSGUvpMdaFucHf4RnA\n0CflW+ElUwKBgBQjffKjwU+AbXUR6a5nY/tLwQQ3JLOxKtgopcF8VMSZjX8m19RZ\n/PZGnWkAHNOmX0FpPCAgi+rHkHtcoJ9Za0vnqcuyJEg5fw8jgRXDCAMCKuKUe8N+\nsEdJNs8EWKvOkdCESaPicktxoVqlU+VsBMCtI0ZXNmUul2MydtyIT8ExAoGBAIO/\nganhvF/EQHOCDq0i0loK8oJeKiKTPcpWCWBkVuEBPeCpovjgWRaqeu9jg4+nzfku\nlXRND/mQtQcj4H/GaaCIEpcPFIMTyN3JrnJRKgJBGNzHetgKVHcxVBDdhdstdGZr\nBWUiwAb6/B0Erh3txXKHmPMI5nuTcfLUtCd6izWzAoGBAIlDe0qU27ioNp8R2acv\nvZCh9puE/rgV0TjkpmZ4BqqTHdOWZ7q4CTw1OccbysMO6gv6xnPzuWTqNygICx32\nXwXR3V7XniS4tQ8hoXWVko4hwUvPo4eQfh99sE2+rcWO8WTHJC8Oa8cEO3wnIBwU\nax/2ccChFaxPVWBePPR6hVc6\n-----END PRIVATE KEY-----\n",
    client_email: "firebase-adminsdk-w8zvh@wiener-carrion-app-movil.iam.gserviceaccount.com",
    client_id: "117567947291913039347",
    auth_uri: "https://accounts.google.com/o/oauth2/auth",
    token_uri: "https://oauth2.googleapis.com/token",
    auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
    client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-w8zvh%40wiener-carrion-app-movil.iam.gserviceaccount.com",
    universe_domain: "googleapis.com",
    storageBucket: "wiener-carrion-app-movil.appspot.com"
  }
}

// 10/09/2022
// export interface Storage {
//   type: string
//   project_id: string
//   private_key_id: string
//   private_key: string
//   client_email: string
//   client_id: string
//   auth_uri: string
//   token_uri: string
//   auth_provider_x509_cert_url: string
//   client_x509_cert_url: string
//   storageBucket: string
// }
// export function getStorageParams(): Storage {
//   return {
//     type: "service_account",
//     project_id: "wiener-carrion-app",
//     private_key_id: "bbfd8786f4b7023bc4a2e109591fcfe57867cc4a",
//     private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCWKwo/GK8lJE1l\nLiN7Ei7RfVjheafvaV6qRkYvOAWmIkmxRkfXb6xQl2ksdkWMs3dBDSp4J8KiZ6Sd\n/HuhSSRlpp9WNGDeF/QnbpOLkyLuoFKnLEJb2a/MlKo4sIbJHZdrk6WSld6izzsl\nTu7rydmqn1fy3yC0uNpWPTwS7bfesxUvMp0sNOHvfASJCYPaC+ux31G0lAKnMTcf\nRYgkYXWC/j3H/X7pzQAkelW+Ye7emmloH0dtoLXUK4eETtNYnV3yA2A8tAo4fyFQ\n82lR5P4hTjk5HH6VV4qbfMK7as1YyWcJ+HxBTby0y5F1BjzWzUliPa9gxLVyEPJa\nqtY0eNsZAgMBAAECggEANfU0ItTfRhTDvIn2FMhFS1/jWm1IwvVVHxDOMjRKj1X9\nrbWnpckKHREyqNe8iOCJhrXQ30jGHm3oUB5DXAI4kk/rK+H5NoRb5flNCdduM1kn\nKct08qUHDpcSjPkdDVHVMUHlugSg8VZOFctFfTLTxAOqaenzygwr49Xv6otBSML6\nQBXLBLBYDlUTAPAc3eAcaWvHycI1B9KY4d2NKQaxcQc5bzYzuN0gGkZtGf4WQIc6\n/SR+b+4x3khQhNweNDwLxaxHQ5Z0ReLQj2/Pohd5kSpqaK63krYwl/qReuJpeZjd\nV3UQmg3mYPeajkoTwNaoEtK2nQrircziZ9cmXa3SIwKBgQDQFJucO8q7cD08Enod\nmfdd8/3Mz+lqQ0h8jleiI8CxdMKB9G2Ub81yDwmKNjRCf7XIldxRPVDEDxwPi7eO\nyymbor/wadTrDoxFP8OAxsqeOz9VOS/WxP/XiASJ85VHgGKMV71UewNgsRvYmx2Z\nNm6fRH9sBBAPZgjMxlNZXiOtgwKBgQC4wDTV9clYveIx6zvcqMqvso/bo68XwbcS\niqQaxpgCa9DrWFSWUQxVnrMncXdqUlIcl1Lavsh5sqSFJoUn3BNFk6Xl1PN2lhEp\n5CTQRkbCiWtqGDskhfsYcUqlEwrFeswWXrxpvrrDQmszZdMoQXnqAZ8tfI2D8SyD\nm3eas8VuMwKBgEoRC9Lw9ZkCuslHSnB+/a5Ty2O8HjnU+Vhc0pTEZTLFfPcFKnxL\nz7Vp5Nttr+x7yRk9Ps8sJDOlrTdbZFtPtd/QbsIE6eVBqGx95flQyY7JSCHt2Tl5\nphfyMqtoKRrqeCm8kYggwXMJl//iNwbyeS34E+M/DvhsOXWqosCM4eZBAoGAaqnl\nl0XhKk/uoN1r4Ti+VHPmJGu7Mh8QIC9VEXRo3LT8mHOV2ncOhPwr+PDEfejrKUCg\ngFQ3kDN4Urtrkxi3M6tL04Z/zY5UeHgUXP7F6ZjHICi/NJX54yG9c9KrnFoKLDwj\n0XVhhEv7cmAuagZraZPoH63SUkPSbGU8x19U9AsCgYAT3EF6CXhOzLSPqNqqewl7\nCydJFsM4QI+msQlkqkxvb3vgvZYNju35YA5Nju51a8rcu+ihLVO9JlT08TDa6wok\nHIruTNZZpOALw7lvkSPF6RwOnEzgKy0zP4N/Ma9CAxuMPXN6BRSmLs5/03s3nfmi\n6mn/mpwYig0e4DLZBW6kAw==\n-----END PRIVATE KEY-----\n",
//     client_email: "firebase-adminsdk-2f9n6@wiener-carrion-app.iam.gserviceaccount.com",
//     client_id: "110712210002942131201",
//     auth_uri: "https://accounts.google.com/o/oauth2/auth",
//     token_uri: "https://oauth2.googleapis.com/token",
//     auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
//     client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-2f9n6%40wiener-carrion-app.iam.gserviceaccount.com",
//     storageBucket: "wiener-carrion-app.appspot.com"
//   }
// }

/**/

import "reflect-metadata";
import {Container} from "inversify";
import getDecorators from "inversify-inject-decorators";
import UserApiRepository from "@/usecase/repository/api/userApiRepository";
import UserLocalStorageRepository from "@/usecase/repository/localStorage/userLocalStorageRepository";
import userApi from "@/data/api/userApi";
import UserLocalStorage from "@/data/localStorage/userLocalStorage";
import {UserSignIn, UseCaseSignIn} from "@/usecase/usecase/user/useCaseSignIn"
import {UseCaseGetSession, GetSession} from "@/usecase/usecase/user/useCaseGetSession";
import {UseCaseSignOut, UserSignOut} from "@/usecase/usecase/user/useCaseSignOut";
import StorageFirebaseRepository from "@/usecase/repository/firebase/storageFirebaseRepository";
import StorageFirebase from "@/data/firebase/storageFirebase";
import {UploadFile, UseCaseUploadFile} from "@/usecase/usecase/firebase/useCaseUploadFile";
import NewsApiRepository from "@/usecase/repository/api/newsApiRepository";
import CampusApiRepository from "@/usecase/repository/api/campusApiRepository";
import NewsApi from "@/data/api/newsApi";
import {GetNews, UseCaseGetNews} from "@/usecase/usecase/news/useCaseGetNews";
import { GetNewsById, UseCaseGetNewsById } from "@/usecase/usecase/news/useCaseGetNewsById";
import { SaveNews, UseCaseSaveNews } from "./usecase/usecase/news/useCaseSaveNews";
import { DeleteNewsById, UseCaseDeleteNewsById } from "./usecase/usecase/news/useCaseDeleteNewsById";
import { GetCampus, UseCaseGetCampus } from "./usecase/usecase/campus/useCaseGetCampus";
import { GetCampusById, UseCaseGetCampusById } from "./usecase/usecase/campus/useCaseGetCampusById";
import { SaveCampus, UseCaseSaveCampus } from "./usecase/usecase/campus/useCaseSaveCampus";
import { DeleteCampusById, UseCaseDeleteCampusById } from "./usecase/usecase/campus/useCaseDeleteCampusById";
import CampusApi from "./data/api/campusApi";
import FeatureApiRepository from "./usecase/repository/api/featureApiRepository";
import FeatureApi from "./data/api/featureApi";
import { GetFeature, UseCaseGetFeature } from "./usecase/usecase/feature/useCaseGetFeature";
import { GetFeatureById, UseCaseGetFeatureById } from "./usecase/usecase/feature/useCaseGetFeatureById";
import { SaveFeature, UseCaseSaveFeature } from "./usecase/usecase/feature/useCaseSaveFeature";
import { DeleteFeatureById, UseCaseDeleteFeatureById } from "./usecase/usecase/feature/useCaseDeleteFeatureById";

const container = new Container();

// REPOSITORY
container
  .bind<UserApiRepository>("UserApiRepository")
  .to(userApi)
  .inSingletonScope()

container
  .bind<UserLocalStorageRepository>("UserLocalStorageRepository")
  .to(UserLocalStorage)
  .inSingletonScope()

container
  .bind<StorageFirebaseRepository>("StorageFirebaseRepository")
  .to(StorageFirebase)
  .inSingletonScope()

container
  .bind<NewsApiRepository>("NewsApiRepository")
  .to(NewsApi)
  .inSingletonScope()

container
  .bind<CampusApiRepository>("CampusApiRepository")
  .to(CampusApi)
  .inSingletonScope()
  
container
  .bind<FeatureApiRepository>("FeatureApiRepository")
  .to(FeatureApi)
  .inSingletonScope()

// USES CASES

// SESSION
container
  .bind<UserSignIn>("UserSignIn")
  .to(UseCaseSignIn)
  .inSingletonScope()

container
  .bind<GetSession>("GetSession")
  .to(UseCaseGetSession)
  .inSingletonScope()

container
  .bind<UserSignOut>("UserSignOut")
  .to(UseCaseSignOut)
  .inSingletonScope()

container
  .bind<UploadFile>("UploadFile")
  .to(UseCaseUploadFile)
  .inSingletonScope()

// NEWS
container
  .bind<GetNews>("GetNews")
  .to(UseCaseGetNews)
  .inSingletonScope()

container
  .bind<GetNewsById>("GetNewsById")
  .to(UseCaseGetNewsById)
  .inSingletonScope()

container
  .bind<SaveNews>("SaveNews")
  .to(UseCaseSaveNews)
  .inSingletonScope()

container
  .bind<DeleteNewsById>("DeleteNewsById")
  .to(UseCaseDeleteNewsById)
  .inSingletonScope()

// CAMPUS
container
  .bind<GetCampus>("GetCampus")
  .to(UseCaseGetCampus)
  .inSingletonScope()

container
  .bind<GetCampusById>("GetCampusById")
  .to(UseCaseGetCampusById)
  .inSingletonScope()

container
  .bind<SaveCampus>("SaveCampus")
  .to(UseCaseSaveCampus)
  .inSingletonScope()

container
  .bind<DeleteCampusById>("DeleteCampusById")
  .to(UseCaseDeleteCampusById)
  .inSingletonScope()

// FEATURE
container
  .bind<GetFeature>("GetFeature")
  .to(UseCaseGetFeature)
  .inSingletonScope()

container
  .bind<GetFeatureById>("GetFeatureById")
  .to(UseCaseGetFeatureById)
  .inSingletonScope()

container
  .bind<SaveFeature>("SaveFeature")
  .to(UseCaseSaveFeature)
  .inSingletonScope()

container
  .bind<DeleteFeatureById>("DeleteFeatureById")
  .to(UseCaseDeleteFeatureById)
  .inSingletonScope()

const {lazyInject} = getDecorators(container);
export {lazyInject, container};

import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import DashboardLayout from '@/app/views/layout/DashboardLayout.vue'
import AuthLayout from '@/app/views/pages/AuthLayout.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: 'news',
    component: DashboardLayout,
    children: [
      {
        path: '/news',
        name: 'Noticias',
        component: () => import(/* webpackChunkName: "demo" */ '../views/news/NewsListView.vue')
      },
      {
        path: '/news/:id_news/edit',
        name: 'Noticias',
        component: () => import(/* webpackChunkName: "demo" */ '../views/news/NewsEditView.vue')
      },
      {
        path: '/news/add',
        name: 'Noticias',
        component: () => import(/* webpackChunkName: "demo" */ '../views/news/NewsAddView.vue')
      },
      {
        path: '/campus',
        name: 'Sedes',
        component: () => import(/* webpackChunkName: "demo" */ '../views/campus/CampusListView.vue')
      },
      {
        path: '/campus/:id_campus/edit',
        name: 'Sedes',
        component: () => import(/* webpackChunkName: "demo" */ '../views/campus/CampusEditView.vue')
      },
      {
        path: '/campus/add',
        name: 'Sedes',
        component: () => import(/* webpackChunkName: "demo" */ '../views/campus/CampusAddView.vue')
      },
      {
        path: '/feature',
        name: 'Features',
        component: () => import(/* webpackChunkName: "demo" */ '../views/feature/FeatureListView.vue')
      },
      {
        path: '/feature/:id_feature/edit',
        name: 'Features',
        component: () => import(/* webpackChunkName: "demo" */ '../views/feature/FeatureEditView.vue')
      },
      {
        path: '/feature/add',
        name: 'Features',
        component: () => import(/* webpackChunkName: "demo" */ '../views/feature/FeatureAddView.vue')
      }
    ]
  },
  {
    path: '/auth',
    redirect: 'auth',
    component: AuthLayout,
    children: [
      {
        path: '/auth',
        name: 'auth',
        component: () => import(/* webpackChunkName: "demo" */ '../views/AuthView.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router

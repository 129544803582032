import { lazyInject } from "@/di";
import { News, Pagination } from "@/domain/entity";
import { DeleteNewsById } from "@/usecase/usecase/news/useCaseDeleteNewsById";
import { GetNews } from "@/usecase/usecase/news/useCaseGetNews";
import { GetNewsById } from "@/usecase/usecase/news/useCaseGetNewsById";
import { SaveNews } from "@/usecase/usecase/news/useCaseSaveNews";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { PayloadPagination } from "./session";

export interface NewsState {
    pagination?: Pagination<News>
  }

@Module({
    name: "news",
    namespaced: true
})
export class NewsStore extends VuexModule implements NewsState {
    public pagination!: Pagination<News>
        
    @lazyInject("GetNews") private getNews!: GetNews;
    @lazyInject("GetNewsById") private getNewsById!: GetNewsById;
    @lazyInject("SaveNews") private saveNews!: SaveNews;
    @lazyInject("DeleteNewsById") private deleteNewsById!: DeleteNewsById;

    @Mutation
    setPagination(pagination: Pagination<News>) {
        this.pagination = pagination
    }

    @Action({rawError: true})
    async callPagination({text, page}: PayloadPagination): Promise<Pagination<News>> {
        return this.getNews.execute(text, page)
    }

    @Action({rawError: true})
    async callById(id_news: string): Promise<News> {
        return this.getNewsById.execute(id_news)
    }

    @Action({rawError: true})
    async callSave(news: News): Promise<boolean> {
        return this.saveNews.execute(news)
    }

    @Action({rawError: true})
    async callDeleteById(id_news: string): Promise<boolean> {
        return this.deleteNewsById.execute(id_news)
    }

}
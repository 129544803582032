import { News } from "@/domain/entity";
import NewsApiRepository from "@/usecase/repository/api/newsApiRepository";
import { inject, injectable } from "inversify";

export interface GetNewsById {
    execute(id_news: string): Promise<News>;
}

@injectable()
export class UseCaseGetNewsById implements GetNewsById {

    constructor(
        @inject("NewsApiRepository") private newsApiRepository: NewsApiRepository) {
    }

    execute(id_news: string): Promise<News> {
        return new Promise<News>((resolve, reject) => {
            this.newsApiRepository.fetchById(id_news)
                .then(news => {
                resolve(news)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}
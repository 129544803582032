import {inject, injectable} from "inversify";
import UserLocalStorageRepository from "@/usecase/repository/localStorage/userLocalStorageRepository";

/* eslint-disable */
export interface UserSignOut {
  execute(): Promise<boolean>;
}

@injectable()
export class UseCaseSignOut implements UserSignOut {

  constructor(
    @inject("UserLocalStorageRepository") private userLocalStorageRepository: UserLocalStorageRepository) {
  }

  execute(): Promise<boolean> {
    this.userLocalStorageRepository.setToken(null).then()
    this.userLocalStorageRepository.setUser(null).then()
    return Promise.resolve(true);
  }

}

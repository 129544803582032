import {BaseResponse} from "@/data/api/response/BaseResponse";
import {UnAuthorizedException} from "@/domain/entity/UnAuthorizedException";
import {ApiException} from "@/domain/entity/ApiException";
import Axios, {AxiosRequestConfig} from "axios";
import {BaseApiError} from "@/domain/entity/BaseApiError";

export async function validateResponse<T>(method: Method, path: string, request?: any): Promise<BaseResponse<T>> {
  const BASE_URL = process.env.VUE_APP_BASE_URL_API
  const token = window.localStorage.getItem('wiener-carriontoken')
  const options: AxiosRequestConfig = {
    method: method,
    headers: {
      'content-type': 'application/json',
      'Authorization': `bearer ${token ? token : ''}`
    },
    data: request,
    url: `${BASE_URL}${path}`
  }

  if(method == Method.GET) delete options.data

  const response: BaseResponse<T> = new BaseResponse<T>()
  try {
    const payloadSignin = await Axios.request(options)
    console.log(`validateResponse(${path}) [payloadSignin]: `, JSON.stringify(payloadSignin))
    if (payloadSignin.data) {
      response.data = (JSON.parse(JSON.stringify(payloadSignin.data)) as T)
    }
  } catch (exception) {
    console.log(`validateResponse(${path}) [exception]: `, JSON.stringify(exception.response))
     if (exception.response && exception.response.status === 401) {
      response.error = new UnAuthorizedException(-1, 'No tienes permiso para realizar dicha acción', 'http 401')
    } else if (exception.response && exception.response.data) {
       response.error = (JSON.parse(JSON.stringify(exception.response.data)) as BaseApiError)
     } else {
      response.error = new ApiException(0, 'Ups algo sucedió, vuelvelo a intentar más tarde.', 'error default')
    }
  }

  return response
}

export enum Method {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE"
}

import { lazyInject } from "@/di";
import { Feature, Pagination } from "@/domain/entity";
import { DeleteFeatureById } from "@/usecase/usecase/feature/useCaseDeleteFeatureById";
import { GetFeature } from "@/usecase/usecase/feature/useCaseGetFeature";
import { GetFeatureById } from "@/usecase/usecase/feature/useCaseGetFeatureById";
import { SaveFeature } from "@/usecase/usecase/feature/useCaseSaveFeature";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { PayloadPagination } from "./session";

export interface FeatureState {
    pagination?: Pagination<Feature>
}

@Module({
    name: "feature",
    namespaced: true
})
export class FeatureStore extends VuexModule implements FeatureState {
    public pagination!: Pagination<Feature>

    @lazyInject("GetFeature") private getFeature!: GetFeature;
    @lazyInject("GetFeatureById") private getFeatureById!: GetFeatureById;
    @lazyInject("SaveFeature") private saveFeature!: SaveFeature;
    @lazyInject("DeleteFeatureById") private deleteFeatureById!: DeleteFeatureById;

    @Mutation
    setPagination(pagination: Pagination<Feature>) {
        this.pagination = pagination
    }

    @Action({rawError: true})
    async callPagination({text, page}: PayloadPagination): Promise<Pagination<Feature>> {
        return this.getFeature.execute(text, page)
    }

    @Action({rawError: true})
    async callById(id_feature: string): Promise<Feature> {
        return this.getFeatureById.execute(id_feature)
    }

    @Action({rawError: true})
    async callSave(model: Feature): Promise<boolean> {
        return this.saveFeature.execute(model)
    }

    @Action({rawError: true})
    async callDeleteById(id_feature: string): Promise<boolean> {
        return this.deleteFeatureById.execute(id_feature)
    }

}
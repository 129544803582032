export class BaseApiError extends Error {
  public code?: number
  public message_user?: string
  public message_dev?: string


  constructor(code?: number, message_user?: string, message_dev?: string) {
    super(message_user);
    this.code = code
    this.message_user = message_user
    this.message_dev = message_dev
  }

}

import { Campus, Pagination } from "@/domain/entity";
import CampusApiRepository from "@/usecase/repository/api/campusApiRepository";
import { inject, injectable } from "inversify";

export interface GetCampus {
    execute(text: string, page: number): Promise<Pagination<Campus>>;
}

@injectable()
export class UseCaseGetCampus implements GetCampus {

    constructor(
        @inject("CampusApiRepository") private campusApiRepository: CampusApiRepository) {
    }

    execute(text: string, page: number): Promise<Pagination<Campus>> {
        return new Promise<Pagination<Campus>>((resolve, reject) => {
            this.campusApiRepository.fetchAll(text, page)
                .then(pagination => {
                resolve(pagination)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}
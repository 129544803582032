<template>
  <router-view></router-view>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";

@Component
export default class App extends Vue {

}
</script>


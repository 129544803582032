import { injectable } from "inversify";
import NewsApiRepository from "@/usecase/repository/api/newsApiRepository";
import { News, Pagination } from "@/domain/entity";
import { Method, validateResponse } from "@/data/api/BaseApi";
import { NewsResponse, PaginationResponse } from "@/data/api/response";
import { newsTo, paginationNewsTo } from "@/data/api/response/mapper";

@injectable()
export default class NewsApi implements NewsApiRepository {
    
    deleteById(id_news: string): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        validateResponse<boolean>(Method.DELETE, `/v1.0.0/news/${id_news}`)
        .then(response => {
          if (!response.error) {
            resolve(true)
          } else {
            reject(response.error)
          }
        })
        .catch(exception => {
          reject(exception)
        })
      })
    }

    save(request: News): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        validateResponse<boolean>(Method.POST, '/v1.0.0/news', request)
        .then(response => {
          if (!response.error) {
            resolve(true)
          } else {
            reject(response.error)
          }
        })
        .catch(exception => {
          reject(exception)
        })
      })
    }

    fetchById(id_news: string): Promise<News> {
      return new Promise<News>((resolve, reject) => {
        validateResponse<NewsResponse>(Method.GET, `/v1.0.0/news/${id_news}`)
            .then(response => {
              if (response.data != null) {
                resolve(newsTo(response.data))
              } else {
                reject(response.error)
              }
            })
            .catch(exception => {
              reject(exception)
            })
      })
    }

    public fetchAll(text: string, page: number): Promise<Pagination<News>> {
        return new Promise<Pagination<News>>((resolve, reject) => {
          validateResponse<PaginationResponse<NewsResponse>>(Method.GET, `/v1.0.0/news?text=${text}&page=${page}`)
            .then(response => {
              if (response.data != null) {
                resolve(paginationNewsTo(response.data))
              } else {
                reject(response.error)
              }
            })
            .catch(exception => {
              reject(exception)
            })
        })
    }

}
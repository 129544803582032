import { Campus } from "@/domain/entity";
import CampusApiRepository from "@/usecase/repository/api/campusApiRepository";
import { inject, injectable } from "inversify";

export interface GetCampusById {
    execute(id_news: string): Promise<Campus>;
}

@injectable()
export class UseCaseGetCampusById implements GetCampusById {

    constructor(
        @inject("CampusApiRepository") private campusApiRepository: CampusApiRepository) {
    }

    execute(id_campus: string): Promise<Campus> {
        return new Promise<Campus>((resolve, reject) => {
            this.campusApiRepository.fetchById(id_campus)
                .then(news => {
                resolve(news)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}
import {inject, injectable} from "inversify";
import UserApiRepository from "@/usecase/repository/api/userApiRepository";
import UserLocalStorageRepository from "@/usecase/repository/localStorage/userLocalStorageRepository";
import { User } from "@/domain/entity";

/* eslint-disable */
export interface UserSignIn {
  execute(email: string, password: string): Promise<boolean>;
}

@injectable()
export class UseCaseSignIn implements UserSignIn {
  constructor(
    @inject("UserApiRepository") private userApiRepository: UserApiRepository,
    @inject("UserLocalStorageRepository") private userLocalStorageRepository: UserLocalStorageRepository) {
  }

  execute(email: string, password: string): Promise<boolean> {
    console.log("execute: ", `email: ${email}, password: ${password}`)
    return new Promise<boolean>((resolve, reject) => {
      this.userApiRepository.signIn(email, password)
        .then(response => {
          this.userLocalStorageRepository.setToken(response.token).then()
          this.userLocalStorageRepository.setUser(
            new User(response.id_user,
              response.role,
              response.name,
              response.lastname,
              response.email,
              response.password,
              response.url_photo,
              response.password_autogenerate,
              response.active,
              response.date_create,
              response.date_update)
          ).then()
          resolve(response.password_autogenerate)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}

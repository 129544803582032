export class User {
    id_user: string
    role: string
    name: string
    lastname: string
    email: string
    password: string
    url_photo: string
    password_autogenerate: boolean
    active: boolean
    date_create: string
    date_update: string

    constructor(id_user: string, role: string, name: string, lastname: string, email: string, password: string, url_photo: string, password_autogenerate: boolean, active: boolean, date_create: string, date_update: string) {
      this.id_user = id_user
      this.role = role
      this.name = name
      this.lastname = lastname
      this.email = email
      this.password = password
      this.url_photo = url_photo
      this.password_autogenerate = password_autogenerate
      this.active = active
      this.date_create = date_create
      this.date_update = date_update
  }
}

export class Session {
    token: string
    id_user: string
    role: string
    name: string
    lastname: string
    email: string
    password: string
    url_photo: string
    password_autogenerate: boolean
    active: boolean
    date_create: string
    date_update: string

    constructor(token: string, id_user: string, role: string, name: string, lastname: string, email: string, password: string, url_photo: string, password_autogenerate: boolean, active: boolean, date_create: string, date_update: string) {
      this.token = token
      this.id_user = id_user
      this.role = role
      this.name = name
      this.lastname = lastname
      this.email = email
      this.password = password
      this.url_photo = url_photo
      this.password_autogenerate = password_autogenerate
      this.active = active
      this.date_create = date_create
      this.date_update = date_update
  }
}

export class Pagination<T> {
  page: number
  per_page: number
  next_page: number
  total: number
  list: T[] | null

  constructor(page: number, per_page: number, next_page: number, total: number, list: T[] | null) {
    this.page = page
    this.per_page = per_page
    this.next_page = next_page
    this.total = total
    this.list = list
  }
}

export class News {
    id_news: string
    id_user: string
    title: string
    description: string
    date: string
    hours: string
    type: string
    action: string
    institution: string
    url_photos: string[]
    active: boolean
    date_create: string
    date_update: string

    constructor(id_news: string, id_user: string, title: string, description: string, date: string, hours: string, type: string, action: string, institution: string, url_photos: string[], active: boolean, date_create: string, date_update: string) {
        this.id_news = id_news
        this.id_user = id_user
        this.title = title
        this.description = description
        this.date = date
        this.hours = hours
        this.type = type
        this.action = action
        this.institution = institution
        this.url_photos = url_photos
        this.active = active
        this.date_create = date_create
        this.date_update = date_update
    }
}

export class Campus {
  id_campus: string
  id_user: string
  name: string
  address: string
  latitude: number
  longitude: number
  institution: string
  practice: boolean
  active: boolean
  date_create: string
  date_update: string

  constructor(id_campus: string, id_user: string, name: string, address: string, latitude: number, longitude: number, institution: string, practice: boolean, active: boolean, date_create: string, date_update: string) {
      this.id_campus = id_campus
      this.id_user = id_user
      this.name = name
      this.address = address
      this.latitude = latitude
      this.longitude = longitude
      this.institution = institution
      this.practice = practice
      this.active = active
      this.date_create = date_create
      this.date_update = date_update
  }
}

export class Feature {
  id_feature: string
  id_user: string
  name: string
  description: string
  institution: string
  active: boolean
  date_create: string
  date_update: string

  constructor(id_feature: string, id_user: string, name: string, description: string, institution: string, active: boolean, date_create: string, date_update: string) {
      this.id_feature = id_feature
      this.id_user = id_user
      this.name = name
      this.description = description
      this.institution = institution
      this.active = active
      this.date_create = date_create
      this.date_update = date_update
  }
}
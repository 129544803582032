import { lazyInject } from "@/di";
import { Campus, Pagination } from "@/domain/entity";
import { DeleteCampusById } from "@/usecase/usecase/campus/useCaseDeleteCampusById";
import { GetCampus } from "@/usecase/usecase/campus/useCaseGetCampus";
import { GetCampusById } from "@/usecase/usecase/campus/useCaseGetCampusById";
import { SaveCampus } from "@/usecase/usecase/campus/useCaseSaveCampus";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { PayloadPagination } from "./session";

export interface CampusState {
    pagination?: Pagination<Campus>
}

@Module({
    name: "campus",
    namespaced: true
})
export class CampusStore extends VuexModule implements CampusState {
    public pagination!: Pagination<Campus>

    @lazyInject("GetCampus") private getCampus!: GetCampus;
    @lazyInject("GetCampusById") private getCampusById!: GetCampusById;
    @lazyInject("SaveCampus") private saveCampus!: SaveCampus;
    @lazyInject("DeleteCampusById") private deleteCampusById!: DeleteCampusById;

    @Mutation
    setPagination(pagination: Pagination<Campus>) {
        this.pagination = pagination
    }

    @Action({rawError: true})
    async callPagination({text, page}: PayloadPagination): Promise<Pagination<Campus>> {
        return this.getCampus.execute(text, page)
    }

    @Action({rawError: true})
    async callById(id_campus: string): Promise<Campus> {
        return this.getCampusById.execute(id_campus)
    }

    @Action({rawError: true})
    async callSave(campus: Campus): Promise<boolean> {
        return this.saveCampus.execute(campus)
    }

    @Action({rawError: true})
    async callDeleteById(id_campus: string): Promise<boolean> {
        return this.deleteCampusById.execute(id_campus)
    }

}
import { Feature } from "@/domain/entity";
import FeatureApiRepository from "@/usecase/repository/api/featureApiRepository";
import { inject, injectable } from "inversify";

export interface GetFeatureById {
    execute(id_feature: string): Promise<Feature>;
}

@injectable()
export class UseCaseGetFeatureById implements GetFeatureById {

    constructor(
        @inject("FeatureApiRepository") private featureApiRepository: FeatureApiRepository) {
    }

    execute(id_feature: string): Promise<Feature> {
        return new Promise<Feature>((resolve, reject) => {
            this.featureApiRepository.fetchById(id_feature)
                .then(news => {
                resolve(news)
            })
            .catch(exception => {
                reject(exception)
            })
        })
    }

}
import {Action, Module, VuexModule} from "vuex-module-decorators";
import {lazyInject} from "@/di";
import {UploadFile} from "@/usecase/usecase/firebase/useCaseUploadFile";
import {PayloadUploadFireStorage} from "@/app/store/session";

export interface FirebaseStorageState { }

@Module({
  name: "firebaseStorage",
  namespaced: true
})
export class FirebaseStorageStore extends VuexModule implements FirebaseStorageState {

  @lazyInject("UploadFile") private uploadFile!: UploadFile;

  @Action({rawError: true})
  async callUploadImage({file, path}: PayloadUploadFireStorage): Promise<string> {
    return this.uploadFile.execute(file, path)
  }

}

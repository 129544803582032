import {inject, injectable} from "inversify";
import UserLocalStorageRepository from "@/usecase/repository/localStorage/userLocalStorageRepository";
import {Session} from "@/domain/entity";

/* eslint-disable */
export interface GetSession {
  execute(): Promise<Session>;
}

@injectable()
export class UseCaseGetSession implements GetSession {

  constructor(
    @inject("UserLocalStorageRepository") private userLocalStorageRepository: UserLocalStorageRepository) {
  }

  async execute(): Promise<Session> {
    try {
      let token = await this.userLocalStorageRepository.getToken()
      let user = await this.userLocalStorageRepository.getUser()
      if (token != null && user != null) {
        return Promise.resolve(new Session(token, user.id_user, user.role, user.name, user.lastname, user.email, user.password, user.url_photo, user.password_autogenerate, user.active, user.date_create, user.date_update))
      } else {
        return Promise.reject(Error('token y user null'))
      }
      
    } catch (exception) { return Promise.reject(exception) }
  }

}













































/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className: string): boolean {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className: string) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import {FadeTransition} from 'vue2-transitions';
import {Component, Vue} from "vue-property-decorator";
import {ItemSideBar, TypeUser, SessionStore} from "@/app/store/session";
import {getModule} from "vuex-module-decorators";

@Component({
  components: {DashboardNavbar, ContentFooter, FadeTransition}
})
export default class DashboardLayout extends Vue {
  nameUser: string = ""
  urlPhoto: string = ""
  items: ItemSideBar[] = []

  get sessionStore(): SessionStore {
    return getModule(SessionStore, this.$store)
  }

  initScrollbar() {
    const isWindows = navigator.platform.startsWith('Win');
    if (isWindows) {
      initScrollbar('sidenav');
    }
  }

  mounted() {
    this.initScrollbar()
  }

  created() {
    this.sessionStore.callGetSession()
      .then(session => {
        console.log('created [session]: ', session)
        if (!session) {
          this.$router.push("/auth");
        } else {
          this.sessionStore.setSession(session)
          this.nameUser = session.name
          this.urlPhoto = session.url_photo

          switch (session.role) {
            case TypeUser.SYSADMIN:
              this.items = this.sessionStore.getterItemsSysAdmin
              break
            case TypeUser.COMPANY_ADMIN:
              this.items = this.sessionStore.getterItemsCompanyAdmin
              break
            case TypeUser.SYS_REGISTER:
              this.items = this.sessionStore.getterItemsSysRegister
              break
          }
        }
      })
      .catch(error => {
        this.$router.push("/auth")
      })
  }
}

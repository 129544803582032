import {VuexModule, Module, Action, Mutation} from "vuex-module-decorators";
import {Session} from "@/domain/entity"
import {lazyInject} from "@/di";
import {UserSignIn} from "@/usecase/usecase/user/useCaseSignIn";
import {GetSession} from "@/usecase/usecase/user/useCaseGetSession";
import {UserSignOut} from "@/usecase/usecase/user/useCaseSignOut";
import router from '@/app/router/index'
import {Vue} from "vue-property-decorator";
import {ApiException} from "@/domain/entity/ApiException";
import {UnAuthorizedException} from "@/domain/entity/UnAuthorizedException";

export interface SessionState {
  session?: Session;
}

export interface PayloadSignin {
  email: string;
  password: string;
}

export interface PayloadPagination {
  text: string;
  page: number;
}

export interface PayloadPaginationVariant {
  text: string;
  page: number;
  approved: boolean;
}

export interface PayloadPaginationTravel {
  text: string;
  page: number;
  status?: string;
}

export interface PayloadUploadFireStorage {
  file: File;
  path: string;
}

export interface PayloadUpdateProfile {
  name: string;
  lastname: string;
  url_photo: string;
  password?: string;
}

export interface ItemSideBar {
  name: string
  path: string
  icon: string
}

export enum TypeUser {
  SYSADMIN = "SYSADMIN",
  SYS_REGISTER = "SYS_REGISTER",
  COMPANY_ADMIN = "COMPANY_ADMIN"
}

@Module({
  name: "session",
  namespaced: true
})
export class SessionStore extends VuexModule implements SessionState {
  public session!: Session

  @lazyInject("UserSignIn") private userSignIn!: UserSignIn;
  @lazyInject("GetSession") private getSession!: GetSession;
  @lazyInject("UserSignOut") private userSignOut!: UserSignOut;

  get getterSession(): Session | null {
    return this.session
  }

  get getterItemsSysAdmin(): ItemSideBar[] {
    return [this.itemNews, this.itemCampus, this.itemFeature]
  }

  get getterItemsSysRegister(): ItemSideBar[] {
    return []
  }

  get getterItemsCompanyAdmin(): ItemSideBar[] {
    return []
  }

  get getterBanks(): string[]{
    return ["BCP", "BBVA", "INTERBANK"]
  }

  get getterTypeDocuments(): string[]{
    return ["DNI", "CE"]
  }

  get getterTypeOffers(): string[] {
    return ["GEO", "NOT_GEO"]
  }

  @Mutation
  setSession(session: Session) {
    this.session = session
  }

  @Action({rawError: true})
  async callSignIn({email, password}: PayloadSignin): Promise<boolean> {
    return this.userSignIn.execute(email, password)
  }

  @Action({rawError: true})
  async callGetSession(): Promise<Session> {
    return this.getSession.execute()
  }

  @Action({rawError: true})
  callSignOut() {
    this.userSignOut.execute()
      .then(success => {
        router.push('/auth')
      })
  }

  @Action
  actionValidateException(exception: Error) {
    console.log('actionValidateException [exception]: ', exception)
    let message: string = 'Error inesperado.'
    if (exception as ApiException) {
      message = (exception as ApiException).message_user!
    } else if(exception as UnAuthorizedException) {
      message = (exception as UnAuthorizedException).message_user!
    }

    Vue.$toast.open({
      message: message,
      type: 'error',
      position: 'bottom-right'
    })

    if(exception instanceof UnAuthorizedException) {
      this.callSignOut()
    }
  }

  private itemNews: ItemSideBar = {
    name: 'Noticias',
    path: '/news',
    icon: 'ni ni-calendar-grid-58 text-white'
  }

  private itemCampus: ItemSideBar = {
    name: "Campus",
    path: "/campus",
    icon: "ni ni-pin-3 text-white"
  }

  private itemFeature: ItemSideBar = {
    name: "Funcionalidades",
    path: "/feature",
    icon: "ni ni-money-coins text-white"
  }

  private itemZoneCompany: ItemSideBar = {
    name: "Zonas Operaciones",
    path: "/zones/company",
    icon: "ni ni-map-big text-white"
  }

  private itemCompany: ItemSideBar = {
    name: "Empresas",
    path: "/companies",
    icon: "ni ni-shop text-white"
  }

  private itemDriver: ItemSideBar = {
    name: "Conductores",
    path: "/drivers",
    icon: "ni ni-satisfied text-white"
  }

  private itemCar: ItemSideBar = {
    name: "Carros",
    path: "/cars",
    icon: "ni ni-delivery-fast text-white"
  }

  private itemCampaign: ItemSideBar = {
    name: "Campañas",
    path: "/campaigns",
    icon: "ni ni-square-pin text-white"
  }

  private itemTravels: ItemSideBar = {
    name: "Viajes",
    path: "/travels",
    icon: "ni ni-map-big text-white"
  }

  private itemUser: ItemSideBar = {
    name: 'Usuarios',
    path: '/users',
    icon: 'ni ni-single-02 text-white'
  }

  private itemProfile: ItemSideBar = {
    name: 'Perfil',
    path: '/profile',
    icon: 'ni ni-circle-08 text-white'
  }

}

import {inject, injectable} from "inversify";
import StorageFirebaseRepository from "@/usecase/repository/firebase/storageFirebaseRepository";

export interface UploadFile {
  execute(file: File, path: string): Promise<string>;
}

@injectable()
export class UseCaseUploadFile implements UploadFile {

  constructor(
    @inject("StorageFirebaseRepository") private storageFirebaseRepository: StorageFirebaseRepository) {
  }

  execute(file: File, path: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.storageFirebaseRepository.upload(file, path)
        .then(url => {
          resolve(url)
        })
        .catch(exception => {
          reject(exception)
        })
    })
  }

}
